import React, { useState, useEffect } from 'react'
import Navbar from '../../Common/Navbar/Navbar'
import Sidebar from '../../Common/Sidebar/Sidebar'
import Loader from '../../Common/Loader/Loader'
import DataTables from '../../Common/Data Tables/DataTables'
import { BASE_URL, SetAlert, handleDynamicRequest, handleDynamicRequestHeader, handleDynamicRequestHeaderMulter } from '../../Common/CommonExport';
import Dashboard from '../Dashboard/Dashboard'
import { useSelector } from 'react-redux';
function WeeklyChart() {
  const subUrl = 'vachanamrut';
  const showEdit = true;
  const dropdownShow = true;
  const [bhajan, setBhajan] = useState([]);
  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
  const userToken = useSelector((state) => state.roleReducer.jwtToken);
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    link: ''
  });
  const columns = [
    { name: 'Sr No', selector: (row) => row.id, sortable: true },
    { name: 'Vachanamrut Name', selector: (row) => row.name, sortable: true },
    {
      name: 'Vachanamrut Link',
      cell: (row) => <a href={row.link} target="_blank">{row.link}</a>,
      sortable: true
  },
  { name: 'Created Date', selector: (row) => row.createdDate, sortable: true }
  ,
  ];

  const handleMainMenu = () => {
    const requestBody = {};
    const method = 'GET';
    const url = `${BASE_URL}/vachanamrut`;
    const wrongResponse = () => {
      setAlertMessage({ success: '', error: 'Wrong Credentials' });
    }

    const rightResponse = (data) => {
      if (data) {
        setBhajan(data.data)
      }
    }
    handleDynamicRequestHeader(method, url, requestBody,userToken, wrongResponse, rightResponse);
  }

  useEffect(() => {
    handleMainMenu();

  }, []);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
  
    if (files && files.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  

  const handleForm = () => {
    return (
      <>
<form onSubmit={handleAdd}>
  <div className="form-group">
    <label htmlFor="bhajanName">Vachanamrut Name</label>
    <input
      className="form-control"
      type="text"
      id="Vachanamrut Name"
      name="name" 
      placeholder="Vachanamrut Name"
      value={formData.name} 
      onChange={handleInputChange} 
    />
  </div>


  <div className="form-group">
    <label htmlFor="bhajanUpload">Vachanamrut Upload</label>
    <input
      type="file"
      id="bhajanUpload"
      name="link"
      className="form-control-file form-control height-auto"
      onChange={handleInputChange} 
    />
  </div>
</form>
      </>
    );
  }


  const handleEditForm = () => {
   
    return (
      <>
<form onSubmit={handleEdit}>
  <div className="form-group">
    <label htmlFor="name">Vachanamrut Name</label>
    <input
      className="form-control"
      type="text"
      id="name"
      name="name" 
      placeholder="Bhajan Name"
      value={formData.name} 
      onChange={handleInputChange} 
    />
  </div>


  <div className="form-group">
    <label htmlFor="vachanamrutUpload">Vachanamrut Upload</label>
    <input
      type="file"
      id="vachanamrutUpload"
      name="link"
      className="form-control-file form-control height-auto"
      onChange={handleInputChange} 
    />
  </div>
</form>
      </>
    );
  }


  const handleAdd = () => {
     const form = new FormData();
    form.append('name', formData.name);
    form.append('bhajanImage', formData.bhajanImage);
    form.append('link', formData.link);
     const method = 'POST';
    const url = `${BASE_URL}/vachanamrut`;
 
    const wrongResponse = () => {
      setAlertMessage({ success: '', error: 'Wrong Credentials' });
    };
   const rightResponse = (data) => {
    handleMainMenu();
     };
      handleDynamicRequestHeaderMulter(method, url, form, userToken, wrongResponse, rightResponse);
  };
  

  const handleEdit = () => {
    const form = new FormData();
   form.append('name', formData.name);
   form.append('bhajanImage', formData.bhajanImage);
   form.append('link', formData.link);
    const method = 'PUT';
   const url = `${BASE_URL}/vachanamrut/${formData.id}`;
  
   const wrongResponse = () => {
     setAlertMessage({ success: '', error: 'Wrong Credentials' });
   };
  const rightResponse = (data) => {
   handleMainMenu();
    };
     handleDynamicRequestHeaderMulter(method, url, form, userToken, wrongResponse, rightResponse);
 };
  return (
    <div>
      		<Loader />
		<Navbar />
		<Sidebar />
		<div className="mobile-menu-overlay"></div>
    <Dashboard>
        <div className="min-height-200px mt-2">
          <div className="page-header" style={{ height: '70vh' }}>
            <div className="row">
            <DataTables columns={columns} filteredData={bhajan} subUrl={subUrl} 
              handleEditForm={handleEditForm} setFormData={setFormData} handleForm={handleForm}  
               handleEdit={handleEdit}  dropdownShow={dropdownShow}
              handleAdd={handleAdd} handleMainMenu={handleMainMenu} showEdit={showEdit}/>
            </div>
          </div>
        </div>
      </Dashboard>
    </div>
  )
}

export default WeeklyChart
