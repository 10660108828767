import React, { useState, useEffect } from 'react'
import { BASE_URL, SetAlert, handleDynamicRequest, handleDynamicRequestHeader, handleDynamicRequestHeaderMulter } from '../../Common/CommonExport';
import Navbar from '../../Common/Navbar/Navbar'
import Sidebar from '../../Common/Sidebar/Sidebar'
import Loader from '../../Common/Loader/Loader'
import Login from '../Authentication/Login'
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import Dashboard from './Dashboard'

function Home() {
    const [userData, setUserData] = useState([]);
	const [birthdayWeekData, setBirthdayWeekData] = useState([]);
	const [stepData, setStepData] = useState([]);
	const [bhajanData, setBhajanData] = useState([]);
	const [vachanamrutData, setVachanamrutData] = useState([]);
	const [weeklyData, setWeeklyData] = useState([]);
	const [barChartData, setBarChartData] = useState([]);
	const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const userToken = useSelector((state) => state.roleReducer.jwtToken);

	useEffect(() => {
		handleMainMenu();
		handleMainMenuBirthday();
		handleMainMenuBhajan();
		handleMainMenuVachanamrut();
		handleMainMenuBirthdayWeek();
		handleMainMenuWeekly();
		handleMainMenuBarChart();
	  }, []);
	
	  const handleMainMenu = () => {
		const requestBody = {};
		const method = 'GET';
		const url = `${BASE_URL}/dashboard-steps`;
		const wrongResponse = () => {
		  setAlertMessage({ success: '', error: 'Wrong Credentials' });
		}
		   const rightResponse = (data) => {
		  if (data) {
			setStepData(data.data)
		  }
		}
		handleDynamicRequestHeader(method, url, requestBody, userToken, wrongResponse, rightResponse);
	  }
	  const handleMainMenuBirthday = () => {
		const requestBody = {};
		const method = 'GET';
		const url = `${BASE_URL}/dashboard-birthday`;
		const wrongResponse = () => {
		  setAlertMessage({ success: '', error: 'Wrong Credentials' });
		}
		   const rightResponse = (data) => {
		  if (data) {
			setUserData(data.data)
		  }
		}
		handleDynamicRequestHeader(method, url, requestBody, userToken, wrongResponse, rightResponse);
	  }
	  const handleMainMenuBhajan = () => {
		const requestBody = {};
		const method = 'GET';
		const url = `${BASE_URL}/dashboard-bhajan`;
		const wrongResponse = () => {
		  setAlertMessage({ success: '', error: 'Wrong Credentials' });
		}
		   const rightResponse = (data) => {
		  if (data) {
			setBhajanData(data.data)
		  }
		}
		handleDynamicRequestHeader(method, url, requestBody, userToken, wrongResponse, rightResponse);
	  }
	  const handleMainMenuVachanamrut = () => {
		const requestBody = {};
		const method = 'GET';
		const url = `${BASE_URL}/dashboard-vachanamarut`;
		const wrongResponse = () => {
		  setAlertMessage({ success: '', error: 'Wrong Credentials' });
		}
		   const rightResponse = (data) => {
		  if (data) {
			setVachanamrutData(data.data)
		  }
		}
		handleDynamicRequestHeader(method, url, requestBody, userToken, wrongResponse, rightResponse);
	  }
	  const handleMainMenuBirthdayWeek = () => {
		const requestBody = {};
		const method = 'GET';
		const url = `${BASE_URL}/dashboard-birthday-week`;
		const wrongResponse = () => {
		  setAlertMessage({ success: '', error: 'Wrong Credentials' });
		}
		   const rightResponse = (data) => {
		  if (data) {
			setBirthdayWeekData(data.data)
		  }
		}
		handleDynamicRequestHeader(method, url, requestBody, userToken, wrongResponse, rightResponse);
	  }
	  const handleMainMenuWeekly = () => {
		const requestBody = {};
		const method = 'GET';
		const url = `${BASE_URL}/dashboard-weekly`;
		const wrongResponse = () => {
		  setAlertMessage({ success: '', error: 'Wrong Credentials' });
		}
		   const rightResponse = (data) => {
		  if (data) {
			setWeeklyData(data.data)
		  }
		}
		handleDynamicRequestHeader(method, url, requestBody, userToken, wrongResponse, rightResponse);
	  }
	  const handleMainMenuBarChart = () => {
		const requestBody = {};
		const method = 'GET';
		const url = `${BASE_URL}/dashboard-barchart`;
		const wrongResponse = () => {
		  setAlertMessage({ success: '', error: 'Wrong Credentials' });
		}
		   const rightResponse = (data) => {
		  if (data) {
			setBarChartData(data.data)
		  }
		}
		handleDynamicRequestHeader(method, url, requestBody, userToken, wrongResponse, rightResponse);
	  }
	console.log(barChartData.map(entry => entry.count))
	  const [chartOptions, setChartOptions] = useState({
		series: [{
		  name: 'Step Count',
		  data: barChartData.map(entry => entry.count)
		}],
		options: {
		  chart: {
			type: 'bar',
			height: 350,
			stacked: true
		  },
		  plotOptions: {
			bar: {
			  horizontal: false,
			},
		  },
		  xaxis: {
			categories: barChartData.map(entry => entry.day)
		  },
		  legend: {
			position: 'top',
		  },
		  fill: {
			opacity: 1
		  }
		}
	  });
	  
	  useEffect(() => {
		// Fetch data and update chart options when barChartData changes
		if (barChartData.length > 0) {
		  setChartOptions(prevOptions => ({
			...prevOptions,
			series: [{
			  name: 'Step Count',
			  data: barChartData.map(entry => entry.count)
			}],
			options: {
			  ...prevOptions.options,
			  xaxis: {
				categories: barChartData.map(entry => entry.day)
			  }
			}
		  }));
		}
	  }, [barChartData]);
  return (
    <div>
      <Dashboard>
      <>
					<div class="xs-pd-20-10 pd-ltr-20">
				<div class="title pb-20">
					<h4 class="h4 mb-0">Today's Overview</h4>
				</div>

				<div class="row">
					<div class="col-xl-3 col-lg-3 col-md-6 mb-20">
						<div class="card-box widget-style3">
							<div class="d-flex flex-wrap">
								<div class="widget-data">
									{/* <div class="weight-700 font-24 text-dark"></div> */}
									<div class="font-14 text-secondary weight-500">
										Today birthday {userData.count}
									</div>
								</div>
								<div class="widget-icon">
									<div class="icon" data-color="#fff">
										<i class="icon-copy dw dw-calendar1"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-lg-3 col-md-6 mb-20">
						<div class="card-box widget-style3">
							<div class="d-flex flex-wrap">
								<div class="widget-data">
									{/* <div class="weight-700 font-24 text-dark"></div> */}
									<div class="font-14 text-secondary weight-500">
										Total Steps {stepData.count}
									</div>
								</div>
								<div class="widget-icon">
									<div class="icon" data-color="#fff">
										<span class="icon-copy ti-heart"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-lg-3 col-md-6 mb-20">
						<div class="card-box widget-style3">
							<div class="d-flex flex-wrap">
								<div class="widget-data">
									{/* <div class="weight-700 font-24 text-dark"></div> */}
									<div class="font-14 text-secondary weight-500">
										Total Bhajan {bhajanData.count}
									</div>
								</div>
								<div class="widget-icon">
									<div class="icon">
										<i
											class="icon-copy fa fa-music"
											aria-hidden="true"
										></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-lg-3 col-md-6 mb-20">
						<div class="card-box widget-style3">
							<div class="d-flex flex-wrap">
								<div class="widget-data">
									{/* <div class="weight-700 font-24 text-dark"></div> */}
									<div class="font-14 text-secondary weight-500">Vachanamrut {vachanamrutData.count} </div>
								</div>
								<div class="widget-icon">
									<div class="icon" data-color="#fff">
										<i class="icon-copy fa fa-book" aria-hidden="true"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row pb-10">
				<div className="col-md-8 mb-20">
      <div className="card-box pd-20">
        <div className="d-flex flex-wrap justify-content-between align-items-center pb-0 pb-md-3">
          <div className="h5 mb-md-0">Weekly Step Count</div>
          {/* <div className="form-group mb-md-0">
            <select className="form-control form-control-sm selectpicker">
              <option value="">Last Week</option>
              <option value="">Last Month</option>
              <option value="">Last 6 Months</option>
              <option value="">Last 1 Year</option>
            </select>
          </div> */}
        </div>
        <ReactApexChart options={chartOptions.options} series={chartOptions.series} type="bar" height={350} />
      </div>
    </div>
					<div class="col-md-4 mb-20">
						<div
							class="card-box min-height-200px pd-20 mb-20"
							data-bgcolor="#455a64"
						>
							<div class="d-flex justify-content-between pb-20 text-white">
								<div class="icon h1 text-white">
									<i class="fa fa-birthday-cake" aria-hidden="true"></i>
								
								</div>
								<div class="font-14 text-right">
									<div><i class="icon-copy ion-arrow-up-c"></i> 2.69%</div>
									<div class="font-12">Since last month</div>
								</div>
							</div>
							<div class="d-flex justify-content-between align-items-end">
								<div class="text-white">
									<div class="font-14">This Week Birthday</div>
									<div class="font-24 weight-500">{birthdayWeekData.count}</div>
								</div>
								<div class="max-width-150">
									<div id="appointment-chart"></div>
								</div>
							</div>
						</div>
						<div class="card-box min-height-200px pd-20" data-bgcolor="#265ed7">
							<div class="d-flex justify-content-between pb-20 text-white">
								<div class="icon h1 text-white">
									<i class="fa fa-users" aria-hidden="true"></i>
								</div>
								<div class="font-14 text-right">
									<div><i class="icon-copy ion-arrow-down-c"></i> 3.69%</div>
									<div class="font-12">Since last month</div>
								</div>
							</div>
							<div class="d-flex justify-content-between align-items-end">
								<div class="text-white">
									<div class="font-14">This Week Participation</div>
									<div class="font-24 weight-500">{weeklyData.count}</div>
								</div>
								<div class="max-width-150">
									<div id="surgery-chart"></div>
								</div>
							</div>
						</div>
					</div>
				</div>


			
				
		
			</div>
					</>
      </Dashboard>
    </div>
  )
}

export default Home
