import React, { useState, useEffect } from 'react'
import { BASE_URL, SetAlert, handleDynamicRequest, handleDynamicRequestHeader, handleDynamicRequestHeaderMulter } from '../../Common/CommonExport';
import Navbar from '../../Common/Navbar/Navbar'
import Sidebar from '../../Common/Sidebar/Sidebar'
import Loader from '../../Common/Loader/Loader'
import Login from '../Authentication/Login'
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';

//Test pull Request by rahul 1234
//Test pull Request by rahul 12345
//Test pull Request by rahul 123456
//Test pull Request by rahul 1234567
//Test pull Request by rahul 12345678
//Test pull Request by rahul 12345679
//Test pull Request by rahul 123456710
//Test pull Request by rahul 123456711

function Dashboard({children}) {
	const currentDate = new Date();
  
	const currentYear = new Date().getFullYear();
	  
	return (
		<div>
		<Loader />
		<Navbar />
		<Sidebar />
		<div className="mobile-menu-overlay"></div>
				<div className="main-container">
					<div className="pd-ltr-20 xs-pd-20-10 pb-3" style={{ maxHeight: '90vh' }}>
						{/* <div className="d-flex justify-content-between "></div> */}
						{children}				
					</div>				
				</div>
				<div className="footer-wrap pd-10 card-box">
					&copy;&nbsp;{currentYear}&nbsp;&nbsp;<a href="https://codelaksh.in" target="_blank">CodeLaksh</a>&nbsp;&nbsp;All Rights Reserved.
				</div>
		</div>

	)
}

export default Dashboard
