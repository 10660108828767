import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { publicRoutes, authProtectedRoutes } from "./Routes";
import LoginAuthentication from "./Components/Pages/Authentication/Login";
import { useSelector } from 'react-redux';

function App() {
  const Login = LoginAuthentication;
  const userRole = useSelector((state) => state.roleReducer.userRole);
    const sessionToken = sessionStorage.getItem('sessionToken'); 


  return (
    <Router>
      <Routes>
       {!sessionToken &&
          publicRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<route.component />}
            />
          ))
        }

        {authProtectedRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              sessionToken ? <route.component /> : <Navigate to="/" />
            }
          />
        ))}
        {!sessionToken && <Route path="/*" element={<Navigate to="/" />} />}
        {sessionToken && userRole && authProtectedRoutes.some(route => route.path === "/dashboard") && (
          <Route path="/*" element={<Navigate to="/dashboard" />} />
        )}
      </Routes>
    </Router>
  );
}

export default App;
